.single {
  display: flex;
  .singleContainer {
    flex: 6;
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
    padding: 20px;
    h1 {
      padding: 10px;
      font-size: 24px;
      color: #555;
    }
    .main-container {
      display: flex;
      gap: 15px;
      @media (max-width: 480px) {
        flex-direction: column;
      }

      .imgsConatiner {
        margin-top: 20px;
        width: 50%;
        height: 100%;
        display: flex;
        gap: 5px;
        @media (max-width: 480px) {
          width: 100%;
          height: 60%;
          flex-direction: column-reverse;
        }
        .sub-img-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          @media (max-width: 480px) {
            flex-direction: row;
          }
          .sub-img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            @media (max-width: 480px) {
              width: 70px;
              height: 70px;
            }
          }
        }
        .main-img {
          width: 100%;
          height: 80vh;
          object-fit: cover;
          @media (max-width: 480px) {
            height: 60vh;
          }
        }
      }
      .infoContainer {
        margin-bottom: 20px;
        padding: 10px;
        width: 50%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 480px) {
          width: 100%;
        }

        .title {
          display: flex;
          flex-direction: column;
          gap: 15px;
          h3 {
            font-size: 24px;
            font-weight: bold;
            @media (max-width: 480px) {
              font-size: 20px;
            }
          }
        }
        .pricing {
          display: flex;
          flex-direction: column;
          gap: 5px;
          h3 {
            font-size: 24px;
            font-weight: bold;
            @media (max-width: 480px) {
              font-size: 20px;
            }
          }
          .taxInfo {
            display: flex;
            flex-direction: column;
            gap: 3px;
            span {
              font-size: 15px;
              font-weight: bold;
            }
          }
        }
        .infoDetails {
          .categoryCont {
            display: flex;
            align-items: center;
            gap: 5px;
          }
          h2 {
            font-size: 24px;
            font-weight: bold;
            color: #555;
            margin-top: 10px;
            margin-bottom: 10px;
            @media (max-width: 480px) {
              font-size: 20px;
            }
          }
          span {
            width: 10%;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #000;
            color: white;
            padding: 5px 10px;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            &:hover {
              background: #444;
            }
          }
        }
        button {
          border-style: none;
          background: #ffff;
          color: #000;
          border: 2px solid black;
          width: 90%;
          font-size: 18px;
          cursor: pointer;
          font-weight: bold;
          padding: 10px 0px;
          border-radius: 20px;
          &:hover {
            background: lightgray;
          }
        }
      }
    }
  }
  .desc {
    font-size: 22px;
    font-weight: bold;
    color: #555;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  .userImg {
    display: flex;
    justify-content: center;

    .userProfile {
      width: 180px;
      height: 180px;
      object-fit: contain;
      border-radius: 50%;
      @media (max-width: 480px) {
        width: 60px;
        height: 60px;
      }
    }
  }
  .subUserInfo{
    h3{
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 10px 0px;
      span{
        font-size: 15px;
        color: gray;
      }
      .true{
        color: rgb(27, 209, 27);
      }
      .false{
        color: red;
      }
    }
  }
}
