.edit {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.548);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    background-color: rgba(255, 255, 255, 0.521);
    font-weight: 700;
    font-size: 12px;
    border-radius: 2px;
    padding: 8px;
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }

  .editContainer {
    width: 40%;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.445);
    gap: 40px;
    border-radius: 10px;
    padding: 20px;

    @media (max-width: 480px) {
      width: 80%;
      height: 80%;
     background-color: rgba(0, 0, 0, 0.719);
    }

    input,
    textarea {
      background-color: transparent;
      color: rgb(230, 227, 227);
      outline: none;
      border: none;
      padding: 10px 10px;
      resize: none;
      border-bottom: 1px solid rgba(211, 211, 211, 0.315);

      &::placeholder {
        color: rgb(202, 202, 202);
      }
    }
    button {
      border: none;
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.219);
      color: rgb(148, 148, 148);
      font-size: 22px;
      border-radius: 10px;
      font-weight: 500;
      border: 0.5px solid rgba(211, 211, 211, 0.315);
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.219);
        color: rgb(202, 202, 202);
      }
    }
  }
}
