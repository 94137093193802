.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 230, 230);
  height: 100vh;
  background-color: white;

  .top {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 480px) {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      @media (max-width: 480px) {
        gap: 25px;
        margin-top: 20px;
      }

      .title {
        font-size: 12px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;

        @media (max-width: 480px) {
          display: none;
        }
      }

      li {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          color: #7451f8;
          font-size: 18px;
          @media (max-width: 480px) {
            font-size: 24px;
          }
        }
        span {
          font-size: 13px;
          font-weight: 700;
          color: #888;

          @media (max-width: 480px) {
            display: none;
          }
        }
      }
    }
  }
}
