.widget {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 14px;
      color: rgb(180, 180, 180);
    }

    .counter {
      font-size: 28px;
      font-weight: 400;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;

      &.positve {
        color: green;
      }
      &.negative {
        color: red;
      }
    }
    .positve {
      color: green;
    }
    .negative {
      color: red;
    }

    .icon {
      font-size: 18px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
      cursor: pointer;
    }
    .moreDetails {
      color: #0606e7;
      font-size: 12px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  .right {
    align-items: center;
  }
}
