.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    p {
      color: black;
      margin-bottom: 20px;
      text-align: center;
    }
    .login-heading {
      font-size: 2rem;
      text-align: center;
      color: rgb(240, 70, 40);
    }
    width: 100%;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .login-label {
    display: none;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .login-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }

  .login-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #222;
    }
  }
}
