.feature {
  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: gray;

    .title {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .fChart {
      width: 100px;
      height: 100px;
    }
    .title {
      font-weight: 500;
      color: gray;
    }
    .amount {
      font-size: 30px;
      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    .desc {
      font-weight: 400;
      font-size: 12px;
      color: gray;
      text-align: center;
    }
    .summary {
      width: 100%;
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        gap: 10px;

        .itemTitle {
          font-size: 20px;
          color: gray;
          font-weight: 500;
          cursor: pointer;
        }

        .itemResult {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 20px;
        }
      }
    }
  }
}
