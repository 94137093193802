.new {
  display: flex;

  .newContainer {
    flex: 6;
    overflow-y: scroll;
    height: 100vh;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        display: flex;
        // align-items: center;
        justify-content: center;

        img {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          object-fit: cover;
          border: 0.3px solid rgb(241, 241, 241);
        }
        @media (max-width: 480px) {
          display: none;
        }
      }
      .right {
        flex: 2;

        .form {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 20px;

          .imgInput {
            display: flex;
            align-items: center;

            .green {
              margin-left: 20px;
              color: green;
              font-weight: 500;
              font-size: 13px;
              top: 50px;
              right: 50px;
            }
            .red {
              margin-left: 20px;
              color: tomato;
              font-weight: 500;
              font-size: 13px;
              top: 50px;
              right: 50px;
            }
          }
          .coustom {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .formInput {
            width: 80%;
            .coustom-container {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
            }

            .coustom-sub-container {
              padding: 5px 10px;
              border-radius: 5px;
              border: 1px solid lightgray;
              cursor: pointer;
              transition: background-color 0.3s ease;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: rgb(125, 238, 125);
              }

              &.selected {
                background-color: rgb(125, 238, 125);
              }
            }

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #444;
              font-weight: 500;
              font-size: 14px;

              .icon {
                cursor: pointer;
                font-size: 16px;
              }
            }

            input {
              width: 100%;
              outline: none;
              border: none;
              padding: 5px;
              color: gray;
              font-size: 12px;
              font-weight: 500;
              border-bottom: 1px solid lightgray;
            }
          }

          button {
            width: 150px;
            border: none;
            padding: 10px;
            background-color: teal;
            color: white;
            font-weight: 500;
            cursor: pointer;
            margin-top: 10px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
