// ColorCircles.scss

.color-circles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.color-circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid lightgray;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  &.selected {
    border: 2px solid #333;
  }
}
