.home {
  display: flex;

  .homeContainter {
    flex: 6;
    overflow-y: scroll;
    height: 100vh;

    h1 {
      padding: 20px;
      margin-bottom: 20px;
      font-size: 24px;
      color: #999;
      @media (max-width: 480px) {
        margin-top: 12px;
        margin-bottom: 0px;
        font-size: 20px;
        text-align: center;
        text-decoration: underline;
      }
    }

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
    .charts {
      padding: 5px 20px;
    }
    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
    }
    .link {
      color: inherit;
      text-decoration: none;

      .listTitle {
        font-weight: 500;
        column-rule: gray;
        margin-bottom: 50px;
      }
    }
  }
}
